import { OpenPanel } from '@openpanel/web';
 
export const openPanel = new OpenPanel({
  apiUrl: 'https://op.coredacao.com/api',
  clientId: '5e6d3663-8b3b-49db-9f24-29fef1259b34',
  clientSecret: 'sec_53ca45e660cf73e9cc49',
  trackScreenViews: true,
  trackOutgoingLinks: false,
  trackAttributes: false,
});

